<template>
  <div class="course">
    <TabPageLayout activeCode="yizhongkecheng">
      <div class="container schoolCourse">
        <div
          class="div_mod div_mod1"
          v-for="(block, index) in blocks"
          :key="block.id"
        >
          <div class="div_tit">
            <h2>{{ block.title }}</h2>
          </div>
          <!-- 栏目简介 -->
          <div class="div_text" v-html="block.bz"></div>

          <div class="" v-if="index === 1">
            <CourseTypeBlock :courseType="courseType" />
            <!-- 课程封面 -->
            <div class="lunbo">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in courseCoverList"
                    :key="item.id"
                  >
                    <img :src="item.files" alt="" />
                  </div>
                </div>
              </div>
              <div class="direction">
                <span class="s_prev"><i></i></span>
                <span class="s_next"><i></i></span>
              </div>
            </div>
          </div>

          <ArticleCard2
            v-for="(article, index) in blockArticles[index]"
            :key="article.id"
            :article="article"
            activeCode="yizhongkecheng"
            :class="{
              dlSp: (index + 1) % 2 === 0,
            }"
          />
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ArticleCard2 from './components/ArticleCard2.vue'
import TabPageLayout from './components/TabPageLayout.vue'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import { useGetCategories } from './utils/useGetCategories'
import CourseTypeBlock from './components/CourseTypeBlock.vue'

export default {
  components: {
    TabPageLayout,
    ArticleCard2,
    CourseTypeBlock,
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    typeId() {
      return (
        this.nav?.find((item) => item.code === 'yizhongkecheng')?.stypes[0]
          ?.id || undefined
      )
    },
    schoolYear() {},
  },
  data() {
    return {
      blocks: [],
      blockArticles: [],
      courseType: [],
      courseCover: null,
      courseCoverList: [],
    }
  },
  methods: {
    async getBlocks() {
      const categories = await useGetCategories(this.typeId)
      console.log({ categories })
      this.blocks = categories.list
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const articles = []
      for (const block of this.blocks) {
        console.log(block, 'block')
        let reqData = {
          noSchoolId: true,
          schoolId: '7',
          pageNum: 1,
          pageSize: 999,
          typeId: block.id,
          isContent: 'Y',
          applyState: 'Y',
        }
        let resData = await portalApi.getArtsByPage(reqData)
        console.log(resData, 'articles')
        articles.push(resData?.list)
        console.log(articles, 'articles')
      }
      this.blockArticles = articles
    },
    async getCourseType() {
      const resData = await useGetCategories(this.blocks[1].id)
      console.log(resData, 'courseType')

      this.courseType = resData.list.filter(item => item.sort !== 0)
      this.courseCover = resData.list.filter(item => item.sort === 0)[0]
      this.$store.commit('setCourseTypes', this.courseType)
      await this.getCourseCoverList()
    },
    async getCourseCoverList() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: 1,
        pageSize: 999,
        typeId: this.courseCover.id,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      this.courseCoverList = resData?.list
    },
    async toCourseTypePage(typeId) {
      this.$router.push({
        path: '/portalYZSYCourseNormalDetail',
        query: {
          typeId,
        },
      })
    },
  },
  async mounted() {
    await this.getBlocks()
    await this.getArticles()
    await this.getCourseType()

    new Swiper('.lunbo .swiper-container', {
      loop: true,
      slidesPerView: 1, // 一行显示3个
      spaceBetween: 30,
      navigation: {
        nextEl: '.lunbo .s_next',
        prevEl: '.lunbo .s_prev',
      },

      breakpoints: {
        1024: {
          // 当屏幕宽度大于等于320
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
    })
  },
}
</script>

<style lang="less" scoped>
@import "./style/course.css";
@import "./style/common.css";
@import "./style/global.css";

.lunbo {
  background: transparent;
}
</style>
